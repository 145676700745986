/** 
 * Trap focus
 */

function trapFocus(elem) {
    var tabbable = jQuery(elem).find('select, input, textarea, button, a').filter(':visible');

    var firstTabbable = tabbable.first();
    var lastTabbable = tabbable.last();
    /*set focus on first input*/
    setTimeout(function() {
        firstTabbable[0].focus();
    }, 200);


    /*redirect last tab to first input*/

    lastTabbable.on('keydown', function(e) {
        if ((e.key === 'Tab' && !e.shiftKey)) {
            e.preventDefault();
            firstTabbable[0].focus();
        }
    });

    /*redirect first shift+tab to last input*/
    firstTabbable.on('keydown', function(e) {
        if ((e.key === 'Tab' && e.shiftKey)) {
            e.preventDefault();
            lastTabbable[0].focus();
        }
    });
}

function unTrapFocus(lastFocused) {
    lastFocused.focus();
}