// Imports

//=require svg4everybody/dist/svg4everybody.min.js
//=require lazysizes/lazysizes.min.js
//=require jquery/dist/jquery.min.js
//=require jquery-validation/dist/jquery.validate.min.js
//=require jquery-mask-plugin/dist/jquery.mask.min.js

//=require ../source/js/tools/tools.filter-input.js
//=require ../source/js/tools/tools.stop-transition-on-resize.js
//=require ../source/js/tools/tools.focus-on-keydown.js
//=require ../source/js/tools/tools.trap-focus.js

svg4everybody();

//=require ../source/js/components/components.input-filters.js
//=require ../source/js/components/components.site-header.js
//=require ../source/js/components/components.site-footer.js
//=require ../source/js/components/components.google-recaptcha.js
//=require ../source/js/components/contact-us/components.contact-us.js
//=require ../source/js/components/page/components.media-block.js
//=require ../source/js/components/page/components.replace-hypen.js
//=require ../source/js/components/page/components.osano.js